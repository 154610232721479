import { axios } from '@/utils/request'

/**
 * 增加组织信息表
 */
export function add (parameter) {
  return axios({
    url: '/dept/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除组织信息表
 */
export function del (parameter) {
  return axios({
    url: '/dept/del',
    method: 'post',
    params: parameter
  })
}
/**
 * 编辑组织信息表
 */
export function edit (parameter) {
  return axios({
    url: '/dept/edit',
    method: 'post',
    data: parameter
  })
}
/**
 * 组织树
 */
export function deptTree (parameter) {
  return axios({
    url: '/dept/getDeptTree',
    method: 'get',
    params: parameter
  })
}
/**
 * 获取部门岗位权限配置
 */
export function getDeptMenuConfig (parameter) {
  return axios({
    url: '/dept/get_dept_menu_config',
    method: 'get',
    params: parameter
  })
}
/**
 * 保存部门岗位权限配置
 */
export function saveDeptMenuConfig (parameter) {
  return axios({
    url: '/dept/save_dept_menu_config',
    method: 'post',
    data: parameter
  })
}
