<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="上级部门"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-tree-select
            v-decorator="['parentId', {rules: [{required: true, message: '请选择上级部门'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'deptNameView', key:'key', value: 'key' }"
            placeholder="请选择上级部门"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item
          label="部门类型"
         :labelCol="labelCol"
         :wrapperCol="wrapperCol">
          <a-radio-group v-decorator="['deptType', { initialValue: 1, rules: [{required: true}]}]">
            <a-radio v-for="item in [{value: 1, label: '部门'}, {value: 2, label: '岗位'}]" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          :label="form.getFieldValue('deptType') === 1 ? '部门名称' : '岗位名称'"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入部门名称"
            v-decorator="['deptName', {rules: [{required: true, message: '请输入部门名称'}]}]"
          />
        </a-form-item>

        <a-form-item
          label="排序"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input-number v-decorator="['sort', {rules: [{required: true, message: '请输入排序'}], initialValue: 0}]" />
        </a-form-item>

        <a-form-item
          label="状态"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          v-if="id"
        >
          <a-radio-group v-decorator="['status', {rules: [{required: true, message: '请选择状态'}]}]">
            <a-radio :value="1">
              正常
            </a-radio>
            <a-radio :value="0">
              停用
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          label="备注"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            type="textarea"
            placeholder="请输入备注信息"
            v-decorator="['description']"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { add, deptTree, edit } from '@/api/user-rights/organizemanage'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      // 信息id
      id: undefined,
      title: undefined
    }
  },
  methods: {
    edit (record) {
      this.title = '编辑部门'
      this.handleDeptTree()
      this.visible = true
      // 拿到id
      this.id = record.id

      const fields = ['parentId', 'deptName', 'deptType', 'sort', 'description', 'status']

      this.$nextTick(() => {
        fields.forEach(v => this.form.getFieldDecorator(v))
        this.form.setFieldsValue({
          parentId: record.parentId,
          deptName: record.deptName,
          deptType: record.deptType,
          sort: record.sort,
          description: record.description,
          status: record.status
        })
      })
    },
    add (record) {
      this.title = '新增部门'
      // 设置父级部门
      this.handleDeptTree(record)
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue({
          parentId: record
        })
      }, 100)
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (this.id) {
            this.editSubmit(values)
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    editSubmit (values) {
      edit(Object.assign(values, { id: this.id })).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('编辑成功')
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    addSubmit (values) {
      add(values).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('新增成功')
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.id = undefined
      this.visible = false
      this.title = undefined
    },
    /**
     * taidi
     * 获取组织树信息
     */
    handleDeptTree () {
      this.loading = true
      deptTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.treeData = [{ deptNameView: '顶级部门', key: 0, children: null }]
          this.treeData[0].children = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
